type WorkflowItem = {
  label: string
  name: string
  date: string
}

export default function organizeWorkflow(detailedWorkflow: WorkflowItem[]) {
  const nameMapping: { [key: string]: string } = {
    'order-created': 'Pedido criado',
    'payment-pending': 'Pedido criado',
    'payment-approved': 'Pagamento confirmado',
    'ready-for-handling': 'Preparando pedido',
    handling: 'Preparando pedido',
    invoiced: 'Nota fiscal emitida',
    'delivery-canceled': 'Pedido em transporte',
    'tracking-number': 'Pedido em transporte',
    dispatched: 'Pedido em transporte',
    'invalid-location': 'Pedido em transporte',
    'start-displacement': 'Pedido em transporte',
    'number-not-found': 'Pedido em transporte',
    'returning-package': 'Pedido em transporte',
    'non-existent-customer': 'Pedido em transporte',
    'address-not-found': 'Pedido em transporte',
    'closed-establishment': 'Pedido em transporte',
    'refused-order': 'Pedido em transporte',
    collected: 'Pedido em transporte',
    'lost-order': 'Pedido em transporte',
    'damaged-order': 'Pedido em transporte',
    'missing-recipient': 'Pedido em transporte',
    'request-cancel': 'Pedido cancelado',
    'cancellation-requested': 'Pedido cancelado',
    canceling: 'Pedido cancelado',
    canceled: 'Pedido cancelado',
    returned: 'Pedido cancelado',
    'canceled-by-customer': 'Pedido cancelado',
    finished: 'Pedido entregue',
  }

  const orderedKeys = Object.values(nameMapping)

  const organizedWorkflow: {
    [key: string]: Array<{ label: string; date: string }>
  } = {}

  orderedKeys.forEach((key) => {
    organizedWorkflow[key] = []
  })

  detailedWorkflow.forEach((item) => {
    const key = nameMapping[item.name]

    if (!key) {
      return
    }

    let date = ''

    if (item.date !== '0001-01-01T00:00:00.0000000+00:00') {
      const parsedDate = Date.parse(item.date)

      if (!Number.isNaN(parsedDate)) {
        date = item.date
      }
    }

    organizedWorkflow[key].push({
      label: item.label,
      date,
    })
  })

  Object.keys(organizedWorkflow).forEach((key) => {
    if (organizedWorkflow[key].length === 0) {
      delete organizedWorkflow[key]
    }
  })

  return organizedWorkflow
}
