import { ArrowExternalLink } from 'src/components/Icons/ArrowExternalLink'

import TimelineCanceled from './TimelineCanceled'
import TimelineProgress from './TimelineProgress'
import type { TimeLineType, Workflow } from './types'

import './orderTimeline.scss'

type TrackEvent = (
  eventAction: string,
  eventPage: string,
  dimension?: string
) => void

type OrderTimeLineProps = {
  timeline: TimeLineType
  trackEvent: TrackEvent
}

const TrackingCode = ({
  dispatched,
  trackEvent,
  currentStatus,
}: {
  dispatched: Workflow | undefined
  trackEvent: TrackEvent
  currentStatus: string
}) => {
  if (dispatched?.confirmed && dispatched?.attachment) {
    return (
      <div className="text-primaryBlue text-sm font-semibold">
        <button
          className="flex gap-1 items-center"
          onClick={() => {
            window.open(dispatched?.attachment ?? '', '_blank')
            trackEvent(
              'Rastrear pedido',
              'my account - Minhas compras',
              currentStatus
            )
          }}
        >
          Rastrear <ArrowExternalLink />
        </button>
      </div>
    )
  }

  if (dispatched?.confirmed && !dispatched?.attachment) {
    return null
  }

  return (
    <p className="font-inter text-[12px] font-normal text-[#a4adb7]">
      Seu código de rastreio ficará disponível assim que o pedido for enviado
    </p>
  )
}

const OrderTimeLine = ({ timeline, trackEvent }: OrderTimeLineProps) => {
  const { workflow, currentStatus } = timeline

  const dispatched = workflow.find((item) => item.name === 'tracking-number')

  const isOrderCanceled = currentStatus.name === 'canceled'

  return (
    <div className="py-[40px] sm:py-[60px]">
      <div className="timeline-header mb-8 w-full flex justify-between items-center max-w-[845px]">
        <span className="font-inter text-xl font-medium">
          Status de rastreio
        </span>
        {!isOrderCanceled && (
          <TrackingCode
            trackEvent={trackEvent}
            currentStatus={currentStatus.name}
            dispatched={dispatched}
          />
        )}
      </div>

      {isOrderCanceled ? (
        <TimelineCanceled workflow={workflow} />
      ) : (
        <TimelineProgress
          workflow={workflow}
          currentStatus={currentStatus.name}
        />
      )}
    </div>
  )
}

export default OrderTimeLine
