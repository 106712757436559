import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import './styles.scss'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

type InformativeBlockProps = {
  type: string
  icon?: boolean
  title?: string
  body: {
    main: string
    secondary?: string
  }
  cta?: {
    style: string
    text: string
    link?: string
    clickAction?: () => void
  }
}

const InformativeBlock = ({
  type,
  icon = false,
  title,
  body,
  cta,
}: InformativeBlockProps) => {
  function trackEvent(eventAction: string, dimension28: string) {
    const event = makeEventTrack({
      eventPage: 'my account - Minhas compras',
      eventAction,
    })

    sendEvent({ ...event, dimension28 })
  }

  return (
    <div className={`${type}-container text-[0.875rem] leading-[1.3125rem]`}>
      <div className="flex items-center gap-2 pb-1">
        {icon && (
          <Icon
            className="flex items-center justify-center"
            name={type === 'success' ? 'BlackCheckmark' : 'BlackWarningSign'}
            width={16}
            height={20}
          />
        )}
        {title && <h2 className="font-semibold">{title}</h2>}
      </div>
      <div className="flex flex-col gap-6">
        <p className={`${type}-body`}>{body.main}</p>
        {body.secondary && <p>{body.secondary}</p>}
      </div>
      {cta && (
        <div className="mt-6">
          {cta.style === 'link' ? (
            <Button
              icon={<Icon name="BlackRightArrow" width={16} height={18} />}
              iconPosition="right"
              className="flex items-center gap-0.5 text-primaryBlue font-semibold"
              onClick={() => {
                trackEvent(`${title} - ${body.main}`, cta.text)
                if (cta.clickAction && typeof cta.clickAction === 'function') {
                  cta.clickAction()
                }
              }}
            >
              {cta.text}
            </Button>
          ) : (
            <>
              {cta.link && (
                <button
                  className="flex items-center justify-center rounded-full bg-[#15181B] text-white font-semibold h-8 w-full md:px-6 md:w-fit"
                  onClick={() => {
                    window.open(cta.link, '_blank')
                    trackEvent(`${title} - ${body.main}`, cta.text)
                  }}
                >
                  {cta.text}
                </button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default InformativeBlock
