import { ArrowExternalLink } from 'src/components/Icons/ArrowExternalLink'
import type { Order } from 'src/types/account/order'
import { Link } from 'gatsby'

import { updateTotals } from './updateTotals'
import { ProductList } from './components/ProductList'
import { PaymentAndShipping } from './components/PaymentAndShipping'
import { Summary } from './components/Summary'
import InformativeBlock from '../InformativeBlock'

interface OrderDetailsProps extends Order {
  isOrderSplit: boolean
  trackEvent: (
    eventAction: string,
    eventPage: string,
    dimension?: string
  ) => void
}

const OrderDetails = (props: OrderDetailsProps) => {
  const {
    sellers: [{ name: sellerName }],
    paymentData,
    packageAttachment: { packages },
    shippingData,
    items,
    status,
    totals,
    value: orderTotalValue,
    isOrderSplit,
    timeline: {
      currentStatus: { name, label },
    },
    trackEvent,
  } = props

  const ViewSellerName =
    sellerName.toLowerCase().includes('decathlonpro') ||
    sellerName.toLowerCase().includes('iguasport ltda')
      ? 'Decathlon'
      : sellerName

  const orderTotals = updateTotals(totals)

  const NFReady = status === 'invoiced' || status === 'finished'

  const orderShipped = name === 'finished' || label === 'Pedido entregue'

  const NFLink = packages?.[0]?.invoiceKey

  const orderSplitBlockProps = {
    type: 'info',
    body: {
      main: 'Para uma entrega mais rápida, seu pedido foi dividido em múltiplos pacotes. Acesse “meus pedidos” para rastrear o outro pacote',
    },
  }

  return (
    <section className="my-8">
      <div className="flex flex-col justify-between items-start sm:flex-row sm:items-center">
        <span className="text-xl font-medium">Detalhes do pedido</span>
        {NFReady && packages.length ? (
          <Link
            to={`http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=${NFLink}`}
            className="flex items-center gap-1 text-sm font-semibold text-primaryBlue"
            onClick={() =>
              trackEvent('Nota fiscal', 'my account - Minhas compras', status)
            }
          >
            Nota fiscal
            <ArrowExternalLink fill="#3643BA" size="11" />
          </Link>
        ) : (
          status !== 'canceled' && (
            <span className="text-xs text-[#A4ADB7] text-[12px]">
              A nota fiscal ficará disponível em breve
            </span>
          )
        )}
      </div>

      {isOrderSplit && <InformativeBlock {...orderSplitBlockProps} />}

      {/* Products */}
      <span className="block mb-6 text-xs text-darkGray mt-8">
        Itens vendidos e entregues por <b>{ViewSellerName}</b>
      </span>
      <ProductList
        trackEvent={trackEvent}
        products={items}
        orderShipped={orderShipped}
      />

      {/* Payment and shipping */}
      <PaymentAndShipping
        paymentData={paymentData}
        shippingData={shippingData}
      />

      {/* Summary */}
      <Summary orderTotals={orderTotals} orderTotalValue={orderTotalValue} />
    </section>
  )
}

export default OrderDetails
