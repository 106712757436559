const ArrowDown = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.46973 7.0304L4.53039 5.96974L8.00006 9.43941L11.4697 5.96974L12.5304 7.0304L8.00006 11.5607L3.46973 7.0304Z"
      fill="#101010"
    />
  </svg>
)

export default ArrowDown
