import { getPaymentType } from 'src/components/sections/Account/utils'
import type {
  OrderPaymentData,
  OrderShippingData,
} from 'src/types/account/order'

interface Props {
  paymentData: OrderPaymentData
  shippingData: OrderShippingData
}

export const PaymentAndShipping = ({
  paymentData: { transactions },
  shippingData: {
    logisticsInfo: [logistics],
    selectedAddresses: [address],
  },
}: Props) => {
  // This regex removes parentheses from the selectedSla string
  // Example: Retirada (741) -> Retirada
  const regex = /\s*\([^)]*\)\s*/

  const selectedSla = logistics.selectedSla.replace(regex, '')

  return (
    <div className="flex flex-col mt-16 gap-8 sm:pb-7 sm:border-b-sm border-[#E1E4E7] sm:flex-row sm:gap-28 sm:mt-20">
      <div className="flex flex-col">
        <span className="text-lg mb-3">Pagamento</span>
        <div className="flex flex-col gap-1 text-[#3E4751]">
          {transactions[0].payments.map(
            ({ lastDigits, installments, paymentSystem }, i) => {
              const paymentType = getPaymentType(paymentSystem)

              return (
                <div className="flex gap-1 text-sm items-center" key={i}>
                  <img
                    src={paymentType?.icon ?? ''}
                    alt="Sistema de pagamento"
                    width={30}
                    height={30}
                  />
                  <span className="text-[#3E4751]">
                    {paymentType?.group}{' '}
                    {lastDigits && ` | Final ${lastDigits}`}
                    {installments && ` | ${installments}x sem juros`}
                  </span>
                </div>
              )
            }
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <span className="text-lg mb-3">Entrega</span>
        <div className="flex flex-col text-[#3E4751] text-sm gap-2">
          <span>
            {selectedSla.toUpperCase() === 'RÁPIDA' ||
            selectedSla.toUpperCase() === 'ECONOMICA'
              ? `Entrega ${selectedSla}`
              : selectedSla}
          </span>
          <span>
            {address.street}, {address.number}{' '}
            {address.complement && ` | ${address.complement}`}
            {address.neighborhood && ` - ${address.neighborhood}`}
          </span>
          <span>{address.receiverName}</span>
        </div>
      </div>
    </div>
  )
}
