import { useState } from 'react'
import ArrowDown from 'src/images/icons/ArrowDown'
import organizeWorkflow from 'src/components/sections/Account/utils/organizeWorkflow'

import { TrackingDetailsList } from './TrackingDetailsList'
import type { TimeLineType } from '../Timeline/types'

type OrderTimeLineProps = {
  timeline: TimeLineType
}

export const TrackingDetails = ({ timeline }: OrderTimeLineProps) => {
  const [isDetailsListOpen, setIsDetailsListOpen] = useState(false)
  const { detailedWorkflow, workflow } = timeline
  const dispatched = workflow.find((item) => item.name === 'tracking-number')
  const lastName = Object.keys(organizeWorkflow(detailedWorkflow))
  const lastDate = detailedWorkflow[detailedWorkflow.length - 1].date
  const lastStatus = detailedWorkflow[detailedWorkflow.length - 1].label

  const handleClick = (Label: string) => {
    setIsDetailsListOpen(true)
    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'event_track',
      event_category: 'clique',
      event_action: 'Ver mais detalhes',
      event_label: 'my account - Minhas compras',
      dimension26: `${Label}`,
    })
  }

  return (
    <>
      <div className="w-full border border-[#E1E4E7]">
        <div className="p-4 ">
          <span className="text-xs font-semibold text-darkGray block mb-[8px]">
            {lastName[lastName.length - 1]}
          </span>
          <div className="text-xs font-regular text-darkGray block ">
            {lastDate && (
              <span className="mr-[8px]">
                {new Date(lastDate).getDate().toString().padStart(2, '0')}/
                {(new Date(lastDate).getMonth() + 1)
                  .toString()
                  .padStart(2, '0')}
              </span>
            )}
            <span>{lastStatus}</span>
          </div>
        </div>
        <div className="border-t border-[#E1E4E7]">
          <button
            className="w-full p-4 flex justify-between"
            onClick={() => {
              handleClick(lastName[lastName.length - 1] ?? '')
            }}
          >
            <span className="text-xs font-semibold text-black">
              Ver mais detalhes
            </span>
            <ArrowDown />
          </button>
        </div>
      </div>

      {isDetailsListOpen && (
        <TrackingDetailsList
          isDetailsListOpen={setIsDetailsListOpen}
          detailedWorkflow={detailedWorkflow}
          attachment={dispatched?.attachment}
        />
      )}
    </>
  )
}
