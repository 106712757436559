import { useEffect } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useUserData } from 'src/components/contexts/UserDataContext'
import { getCookie } from 'src/utils/getCookie'
import { useAccountContext } from 'src/components/account/context'
import Loader from 'src/components/ui/Loader'
import { HAVE_WINDOW } from 'src/constants'
import OrderContent from 'src/components/sections/Account/OrderContent'

function Order({ id }: { readonly id: string }) {
  const { userData, handleSetUserData } = useUserData()
  const { isLoading, isLoggedIn } = useAccountContext()

  useEffect(() => {
    const userDataParam = getCookie('currentUserData')

    if (!userDataParam || userDataParam === JSON.stringify(userData)) {
      return
    }

    const parsedUserData = JSON.parse(userDataParam)

    handleSetUserData(parsedUserData)
  }, [handleSetUserData, userData])

  if (!HAVE_WINDOW) {
    return null
  }

  if (!isLoggedIn) {
    window.location.href = 'https://secure.decathlon.com.br/login'

    return null
  }

  return (
    <>
      <GatsbySeo />
      {isLoading && <Loader />}

      <div className="flex flex-col items-center justify-center py-4 mx-auto md:justify-normal md:flex-row md:items-start px-md max-w-7xl font-inter">
        <OrderContent orderId={id} />
      </div>
    </>
  )
}

export default Order
