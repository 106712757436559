import React, { useEffect } from 'react'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'
import { useMobile } from 'src/hooks/useMobile'
import organizeWorkflow from 'src/components/sections/Account/utils/organizeWorkflow'

import type { DetailedWorkflow } from '../Timeline/types'

type TrackingDetailsListProps = {
  detailedWorkflow: DetailedWorkflow[]
  attachment: string | null | undefined
  isDetailsListOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function getChangeClick(
  text: string,
  attachment: string | null | undefined
) {
  if (attachment) {
    const newLabel = text.replace(
      'clique aqui',
      `<a href=${attachment} target="_blank" rel="noopener noreferrer">clique aqui</a>`
    )

    return <span dangerouslySetInnerHTML={{ __html: newLabel }} />
  }

  return text
}

export const TrackingDetailsList = ({
  isDetailsListOpen,
  attachment,
  detailedWorkflow,
}: TrackingDetailsListProps) => {
  const { isMobile } = useMobile()

  useEffect(() => {
    if (isMobile) {
      return () => {}
    }

    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [isMobile])

  const organizedWorkflow = organizeWorkflow(detailedWorkflow)

  return (
    <div
      className={`fixed top-0 left-0 bg-black bg-opacity-40 z-[10000] w-full h-full flex justify-center items-end ${
        !isMobile ? 'fixed top-0 left-0 bg-black bg-opacity-15 z-[10000]' : ''
      }`}
    >
      <div className="form-content flex justify-end h-full w-full">
        <div
          style={{
            scrollbarColor: '#E1E4E7 transparent',
          }}
          className={`bg-white scrollbar-v2 h-full flex flex-col  ${
            isMobile ? 'w-full' : 'w-[491px] overflow-y-auto'
          }`}
        >
          <div className="form-header justify-between items-center border-b h-14 w-full border-neutral04 p-6 flex">
            <span>Rastreio</span>
            <button onClick={() => isDetailsListOpen(false)}>
              <CloseButtonIcon />
            </button>
          </div>
          <div
            className={`${
              isMobile ? 'pt-[32px] px-[16px] pb-[16px]' : 'py-8 px-6'
            } overflow-auto`}
          >
            {Object.entries(organizedWorkflow)
              .reverse()
              .map(([title, items], index) => {
                return (
                  <div
                    key={title}
                    className={`${
                      index !== 0 && 'border-t border-lightGray pt-[24px]'
                    } mb-[24px] `}
                  >
                    <div className="text-xs font-semibold text-darkGray mb-[8px]">
                      {title}
                    </div>
                    {Array.isArray(items) &&
                      (items as any)
                        .toReversed()
                        .map(({ label, date }: any) => (
                          <div key={label} className="flex">
                            <span className="text-xs font-regular text-darkGray">
                              {date && (
                                <span>
                                  {new Date(date)
                                    .getDate()
                                    .toString()
                                    .padStart(2, '0')}
                                  /
                                  {(new Date(date).getMonth() + 1)
                                    .toString()
                                    .padStart(2, '0')}
                                </span>
                              )}
                            </span>
                            <span
                              className={` ${
                                date ? 'ml-[8px]' : 'ml-[40px]'
                              } text-xs font-regular text-black`}
                            >
                              {getChangeClick(label, attachment)}
                            </span>
                          </div>
                        ))}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
